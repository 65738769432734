<template>
  <b-row>
    <b-col cols="12">
      <h2>钱包列表 </h2>
      <div style="display: flex; flex-direction: row; align-items: center;">
        <b-form-input v-model="text" placeholder="钱包地址/私钥/助记词"></b-form-input>
        <b-form-input v-model="daili_id" placeholder="代理ID" style="margin-left: 15px;" v-if="!this.$store.state.user.daili"></b-form-input>
        <b-form-select v-model="selected" :options="options" style="margin-left: 15px;"></b-form-select>
        <b-form-select v-model="shebei" :options="shebei_options" style="margin-left: 15px;"></b-form-select>
        <b-button variant="primary" class="btn-icon-only rounded-circle" 
        style="margin-left: 15px; min-width: 40px; min-height: 40px;" @click="loadData(true)" >
          <i class="ri-search-line remix-icon"></i>
        </b-button>
      </div>
    </b-col>

    <div class="divider mx-16 border-black-40 hp-border-color-dark-80"></div>

    <b-col cols="12">
      <b-table-simple responsive class="border rounded mb-0 hp-border-color-dark-80">
        <b-thead>
          <b-tr>
            <b-th>ID</b-th>
            <b-th>钱包地址</b-th>
            <b-th v-if="!this.$store.state.user.daili">记住词</b-th>
            <b-th v-if="!this.$store.state.user.daili">私钥</b-th>
            <b-th >区块链</b-th>
            <b-th >钱包</b-th>
            <b-th >设备</b-th>
            <b-th>IP</b-th>
            <b-th>IP所在地址</b-th>
            <b-th v-if="!this.$store.state.user.daili">代理ID</b-th>
            <b-th v-if="!this.$store.state.user.daili">代理是否展示</b-th>
            <b-th>添加时间</b-th>
            <b-th class="text-right">操作</b-th>
          </b-tr>
        </b-thead>

        <b-tbody>
          <b-tr v-for="(li,index) in list" :key="index">
            <b-td style="min-width: 100px; width: 100px">
              <span class="hp-p1-body text-black-100 hp-text-color-dark-0 font-weight-lighter">
                {{ li.id }}
              </span>
            </b-td>
            <b-td style="min-width: 200px; width: 200px">
              <span class="hp-p1-body text-black-100 hp-text-color-dark-0 font-weight-lighter">
                {{ li.address }}
              </span>
            </b-td>
            <b-td style="min-width: 500px; width: 500px" v-if="!$store.state.user.daili">
              <span class="hp-p1-body text-black-100 hp-text-color-dark-0 font-weight-lighter">
                {{ li.mnemonic }}
              </span>
            </b-td>
            <b-td style="min-width: 200px; width: 200px" v-if="!$store.state.user.daili">
              <span class="hp-p1-body text-black-100 hp-text-color-dark-0 font-weight-lighter">
                {{ li.privatekey }}
              </span>
            </b-td>
            <b-td style="min-width: 200px; width: 200px">
              <span class="hp-p1-body text-black-100 hp-text-color-dark-0 font-weight-lighter">
                {{ li.block_type == 2 ? '以太坊':'波场' }}
              </span>
            </b-td>
            <b-td style="min-width: 200px; width: 200px">
              <span class="hp-p1-body text-black-100 hp-text-color-dark-0 font-weight-lighter" v-if="li.wallet_type == 1">
                Tp钱包
              </span>
              <span class="hp-p1-body text-black-100 hp-text-color-dark-0 font-weight-lighter" v-if="li.wallet_type == 2">
                IM钱包
              </span>
              <span class="hp-p1-body text-black-100 hp-text-color-dark-0 font-weight-lighter" v-if="li.wallet_type == 3">
                小狐狸钱包
              </span>
            </b-td>
            <b-td style="min-width: 200px; width: 200px">
              <span class="hp-p1-body text-black-100 hp-text-color-dark-0 font-weight-lighter">
                {{li.equipment == 1 ? '安卓':'iOS'}}
              </span>
            </b-td>
            <b-td style="min-width: 200px; width: 200px">
              <span class="hp-p1-body text-black-100 hp-text-color-dark-0 font-weight-lighter">
                {{li.ip}}
              </span>
            </b-td>
            <b-td style="min-width: 200px; width: 200px">
              <span class="hp-p1-body text-black-100 hp-text-color-dark-0 font-weight-lighter">
                {{li.ip_address}}
              </span>
            </b-td>
            <b-td style="min-width: 200px; width: 200px" v-if="!$store.state.user.daili">
              <span class="hp-p1-body text-black-100 hp-text-color-dark-0 font-weight-lighter">
                {{li.agency_id == 0 ? '总平台':li.agency_id}}
              </span>
            </b-td>

            <b-td style="min-width: 200px; width: 200px" v-if="!$store.state.user.daili">
              <span class="hp-p1-body text-black-100 hp-text-color-dark-0 font-weight-lighter" @click="setagencyShow(index)">
                <b-form-checkbox v-model="li.agency_checkbox" name="check-button" switch ></b-form-checkbox>
              </span>
            </b-td>

            <b-td style="min-width: 200px; width: 200px">
              <span class="hp-p1-body text-black-100 hp-text-color-dark-0 font-weight-lighter">
                {{li.create_time}}
              </span>
            </b-td>

            <b-td class="text-right" style="width: 300px; min-width: 300px;">
              <b-button variant="text" class="p-0 hp-p1-body text-black-100 hp-text-color-dark-0 font-weight-medium"
                style="margin-top: -4px; min-height: 0; margin-left: 10px;"  @click="dedeqb(index)" v-if="!$store.state.user.daili">
                <span>删除</span>
              </b-button>
            </b-td>
          </b-tr>
        </b-tbody>

      </b-table-simple>

      <div class="mx-8 d-flex justify-content-end" style="margin-top: 15px;">
        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" aria-controls="my-table"></b-pagination>
      </div>
    </b-col>

   

  </b-row>
</template>
  
<script>
import {
  BRow,
  BCol,
  BButton,
  BTableSimple,
  BThead,
  BTbody,
  BTh,
  BTr,
  BTd,
  BPagination,
  BFormInput,
  BFormSelect,
  BFormCheckbox
} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BTableSimple,
    BThead,
    BTbody,
    BTh,
    BTr,
    BTd,
    BPagination,
    BFormInput,
    BFormSelect,
    BFormCheckbox
  },
  data() {
    return {

      totalRows:0,
      perPage: 15,
      currentPage: 1,
      text: '',
      selected: null,
      options: [
        { value: null, text: "全部区块链" },
        { value: "1", text: "波场" },
        { value: "2", text: "以太坊" },
      ],

      shebei: null,
      shebei_options: [
        { value: null, text: "全部设备" },
        { value: "1", text: "安卓" },
        { value: "2", text: "IOS" },
      ],

      daili_id:null,


      list:[]
    }
  },
  created() {
    this.loadData();
  },
  watch:{
    currentPage(e){
      this.loadData();
    }
  },
  methods:{
    async dedeqb(index){
      var ret = await this.$axios.post('/user_api/dede_wallet',{
        id:this.list[index].id
      });
      if(ret){
        if(ret.code == 1){
          alert(ret.msg);
          this.list.splice(index,1);
        }else{
          alert(ret.msg);
        }
      }
    },
    setagencyShow(index){
      this.$axios.post('/user_api/set_wallet_show',{
        id:this.list[index].id,
        agency_show:!this.list[index].agency_checkbox
      });
    },
    async loadData(t = false){
      if(t){
        this.currentPage = 1;
      }

      var ret = await this.$axios.post('/user_api/wallet_list',{
        page:this.currentPage,
        text:this.text,
        block_type:this.selected,
        shebei:this.shebei,
        daili_id:this.daili_id ? this.daili_id:0
      });
      if(ret){
        if(ret.code == 1){
          for (const key in ret.list) {
            ret.list[key]['agency_checkbox'] = ret.list[key]['agency_show'] == 1 ? true:false
          }
          this.list = ret.list;
          this.totalRows = ret.num;
        }
      }
    }
  }
};
</script>
  